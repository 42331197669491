import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, TextField, Typography, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form, Field } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../components/Checkbox';
import { LoadingButton } from '../../components/LoadingButton';
import { Logo } from '../../components/Logo';
import { RootState } from '../../redux/redux-store';
import { colors } from '../../styling/colors';
import { verifyUserThunk, setPasswordThunk } from '../../redux/thunk/thunks';

interface FormValues {
  password: string;
  confirmPassword: string;
  agree: boolean;
}

export const Signup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const signupError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.signupError
  );
  const verifyUser: any = useSelector((state: RootState) => state.DATA_REDUCER.verifyUser);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const initialValues: any = {
    password: '',
    confirmPassword: '',
    email: verifyUser ? verifyUser.email : '',
    agree: false
  };

  useEffect(() => {
    dispatch(verifyUserThunk());
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    if (verifyUser) {
      await dispatch(setPasswordThunk(values.password, verifyUser));
      actions.setSubmitting(false);
    }
  };

  const signupSchema = Yup.object().shape({
    password: Yup.string().matches(
      /^(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
      .min(10, 'Password must be at least 10 characters')
      .max(64, 'Password must be less than 64 characters')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Required'),
    agree: Yup.boolean().oneOf([true], 'Required')
  });

  const signupExistUserSchema = Yup.object().shape({
    password: Yup.string().matches(
      /^(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
      .min(10, 'Password must be at least 10 characters')
      .max(64, 'Password must be less than 64 characters')
      .required('Required'),
  });

  const handleSSOSubmit = async (values: any, actions: any) => {
    sessionStorage.setItem('userRoleID', verifyUser.user_role_id);
    history.push({ pathname: '/choose-organization' });
  }

  return (
    // invitedUser && (
    <Grid
      alignItems="center"
      className={classes.main}
      container
      justify="center"
      spacing={0}
      style={{ minHeight: '100vh' }}
    >
      {verifyUser ?
        <Grid>
          <Box mb={4} ml={4}>
            <Logo />
          </Box>
          {verifyUser && verifyUser.isVerifiedUser && verifyUser.isSSOUser === false && verifyUser.existingUser === false ? <>
            <Paper className={classes.signupPaper}>
              <Box p={4.5}>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={signupSchema}
                >
                  {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {
                    if (isSubmitting && signupError) {
                      setSubmitting(false);
                    }

                    return (
                      <Form>
                        <Box pb={2.5}>
                          <Typography color="textSecondary" variant="h1">
                            Sign up for FormFlash
                          </Typography>
                        </Box>

                        <Box pb={2.5}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Email Address
                            </Typography>
                          </Box>

                          <Field fullWidth
                            as={TextField}
                            disabled
                            className={classes.textField}
                            name="email"
                            size="small"
                            variant="outlined"
                          />

                          {errors.email && touched.email ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.email}
                            </Typography>
                          ) : null}
                        </Box>

                        <Box pb={2.5}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Password
                            </Typography>
                          </Box>

                          <Field
                            as={TextField}
                            className={classes.textField}
                            name="password"
                            size="small"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />

                          {errors.password && touched.password ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.password}
                            </Typography>
                          ) : null}
                        </Box>
                        <Box pb={2.5}>
                          <Typography style={{ fontSize: '12px', color: 'gray' }}>
                            Must contain:
                          </Typography>
                          <Typography style={{ fontSize: '12px', color: 'gray' }}>
                            <span style={{ marginRight: '25px' }}>An uppercase letter</span> <span>A lowercase letter</span>
                          </Typography>
                          <Typography style={{ fontSize: '12px', color: 'gray' }}>
                            <span style={{ marginRight: '27px' }}>A special character</span> <span>A number</span>
                          </Typography>
                        </Box>

                        <Box pb={2.5}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Repeat Password
                            </Typography>
                          </Box>

                          <Field
                            as={TextField}
                            className={classes.textField}
                            name="confirmPassword"
                            size="small"
                            variant="outlined"
                            autoComplete='off'
                            type={showPassword1 ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword1}
                                    edge="end"
                                  >
                                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />

                          {errors.confirmPassword && touched.confirmPassword ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.confirmPassword}
                            </Typography>
                          ) : null}
                        </Box>

                        <Box mt={-1} pb={3.5}>
                          <Box ml={-1.1}>
                            <Checkbox
                              disableRipple
                              checked={values.agree}
                              color="primary"
                              onClick={() => setFieldValue('agree', !values.agree)}
                            />

                            <Typography
                              className={classes.agreeLabel}
                              display="inline"
                              variant="body1"
                            >
                              I agree to the{' '}
                              <a className={classes.agreeLabel} href="/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>{' '}
                              and{' '}
                              <a className={classes.agreeLabel} href="/terms-of-service" target="_blank">
                                Terms of Service
                              </a>
                            </Typography>
                          </Box>

                          <Box mt={-1}>
                            {errors.agree && touched.agree ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.agree}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>

                        <Box pb={0.5}>
                          {signupError ? (
                            <Typography color="error" variant="subtitle2">
                              {signupError}
                            </Typography>
                          ) : null}

                          <LoadingButton
                            className={classes.submitButton}
                            color="primary"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="submit"
                            variant="contained"
                            aria-label="sign up"
                          >
                            Sign Up
                          </LoadingButton>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Paper>

            <Box pt={2}>
              <Grid alignItems="flex-end" justify={'center'} container>
                <Link className={classes.link} to="/login">
                  <Typography color="primary" variant="subtitle1">
                    I already have an account
                  </Typography>
                </Link>
              </Grid>
            </Box>
          </>
            : (verifyUser && verifyUser.isVerifiedUser && verifyUser.existingUser && verifyUser.isSSOUser === false) ?
              <Paper className={classes.signupPaper}>
                <Box p={4.5}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={signupExistUserSchema}
                  >
                    {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {
                      if (isSubmitting && signupError) {
                        setSubmitting(false);
                      }

                      return (
                        <Form>
                          <Box pb={2.5}>
                            <Typography color="textSecondary" variant="h1">
                              You've been invited to a new role!
                            </Typography>
                          </Box>
                          <Box pb={2} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h4">
                              {verifyUser && verifyUser.organization_name} has invited you as a {verifyUser && verifyUser.role.replace(/_/g, " ")}.
                              Please login with your existing account to accept the invitation.
                            </Typography>
                          </Box>
                          <Box pb={2.5}>
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Email Address
                              </Typography>
                            </Box>

                            <Field fullWidth
                              as={TextField}
                              disabled
                              className={classes.textField}
                              name="email"
                              size="small"
                              variant="outlined"
                            />

                            {errors.email && touched.email ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.email}
                              </Typography>
                            ) : null}
                          </Box>

                          <Box pb={2.5}>
                            <Box pb={0.75} display="flex" justifyContent="space-between">
                              <Typography color="textSecondary" variant="subtitle2">
                                Password
                              </Typography>
                              <Link className={classes.link} to="/forgot-password">
                                <Typography color="primary" variant="body1">
                                  I forgot my password
                                </Typography>
                              </Link>
                            </Box>

                            <Field
                              as={TextField}
                              className={classes.textField}
                              name="password"
                              size="small"
                              variant="outlined"
                              type={showPassword ? 'text' : 'password'}
                              autoComplete='off'
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />

                            {errors.password && touched.password ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.password}
                              </Typography>
                            ) : null}
                          </Box>

                          <Box pb={0.5}>
                            {signupError ? (
                              <Typography color="error" variant="subtitle2">
                                {signupError}
                              </Typography>
                            ) : null}

                            <LoadingButton
                              className={classes.submitButton}
                              color="primary"
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              type="submit"
                              variant="contained"
                              aria-label="sign up"
                            >
                              Accept Invitation
                            </LoadingButton>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
              </Paper>
              : (verifyUser && verifyUser.isVerifiedUser && verifyUser.isSSOUser) ?
                <Paper className={classes.signupPaper}>
                  <Box p={4.5}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={handleSSOSubmit}
                      validateOnBlur={false}
                      validateOnChange={false}
                      validationSchema={false}
                    >
                      {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {
                        if (isSubmitting && signupError) {
                          setSubmitting(false);
                        }

                        return (
                          <Form>
                            <Box pb={2.5}>
                              <Typography color="textSecondary" variant="h1">
                                Sign up for FormFlash
                              </Typography>
                            </Box>
                            <Box pb={2} pt={2} display="flex" justifyContent="center">
                              {verifyUser && verifyUser.organization_name ? <Typography color="textSecondary" variant="h4">
                                {verifyUser && verifyUser.organization_name} has invited you as a {verifyUser && verifyUser.role.replace(/_/g, " ")}.
                                Please register via Single Sign-On.
                              </Typography> :
                                <Typography color="textSecondary" variant="h4">
                                  You have been invited as a {verifyUser && verifyUser.role.replace(/_/g, " ")}. Please register via Single Sign-On.
                                </Typography>}
                            </Box>
                            <Box pb={2.5}>
                              <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Email Address
                                </Typography>
                              </Box>

                              <Field fullWidth
                                as={TextField}
                                disabled
                                className={classes.textField}
                                name="email"
                                size="small"
                                variant="outlined"
                              />

                              {errors.email && touched.email ? (
                                <Typography color="error" variant="subtitle2">
                                  {errors.email}
                                </Typography>
                              ) : null}
                            </Box>

                            <Box pb={0.5}>
                              <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                aria-label="sign up"
                              >
                                Sign Up via Single Sign-On
                              </LoadingButton>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </Paper> :
                <Paper className={classes.signupPaper}>
                  {verifyUser && verifyUser.status === "ACTIVE" ? <Box>
                    <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
                      <Typography color="textSecondary" variant="h2" style={{ textAlign: 'center', fontSize: '30px' }}>Invitation Already Used</Typography>
                    </Box>
                    <Box pb={4} pt={2} display="flex" justifyContent="center">
                      <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center' }}>This invitation was already accepted and the account has been registerd. If you feel this was mistake, please contact your
                        <a target="_blank" href={`mailto:${verifyUser.email}`}
                        // onClick={(e) => {
                        //   window.location.href = verifyUser.email;
                        //   e.preventDefault();
                        // }}
                        > administrator</a> for further information.</Typography>
                    </Box>
                    <Box pb={2} pt={2} display="flex" justifyContent="center">
                      <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center' }}>Need to login?</Typography>
                    </Box>
                    <Box pb={4} pt={2} display="flex" justifyContent="center">
                      <Link className={classes.link} to="/login"><Typography color="primary" variant="h4" style={{ textAlign: 'center', cursor: 'pointer' }}>Click here to return to FormFlash</Typography></Link>
                    </Box>
                  </Box> :
                    <Box>
                      <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
                        <Typography color="textSecondary" variant="h2" style={{ textAlign: 'center', fontSize: '30px' }}>We're Sorry</Typography>
                      </Box>
                      <Box pb={4} pt={2} display="flex" justifyContent="center">
                        <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center' }}>This invitation has expired. Please contact your
                          <a target="_blank" href={`mailto:${verifyUser && verifyUser.email}`}
                          // onClick={(e) => {
                          //   window.location.href = verifyUser.email;
                          //   e.preventDefault();
                          // }}
                          > administrator</a> for further information and ask them to reinvite you.</Typography>
                      </Box>
                    </Box>}
                </Paper>
          }
        </Grid> :
        <Grid><CircularProgress /></Grid>
      }
    </Grid>
    // )
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: '100vh'
  },
  signupPaper: {
    margin: '0 2rem',
    maxWidth: '30rem',
    width: '80vw'
  },
  textField: {
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  agreeLabel: {
    color: colors.text4
  },
  submitButton: {
    width: '100%'
  }
}));
