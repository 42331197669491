import { AppointmentsType, getAppointmentsAction, getPatientFamilyAction, getAllPatientFamilyAction } from '../data/data-actions';
import { snackbarAlertAction, setSuccessAction, setErrorAction } from '../display/display-actions';
import { getHttp, postHttp, putHttp, deleteHttp } from '../api';
import { RootState, FlowproThunkDispatch } from '../redux-store';
import { AppointmentSearchResults } from '../../shared/domain';
import { push } from 'connected-react-router';

const location: any = window.location;

export interface GetAppointmentsProps {
  appointmentType?: AppointmentsType;
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
}
export const getPatientAppointmentThunk = ({
  appointmentType = 'appointments',
  order = 'DESC'
}: GetAppointmentsProps, patientId: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(getPatientFamilyAction(null));
    const appointments: AppointmentSearchResults = await getHttp(
      `/appointment?order=${order}&patientId=${patientId}`
    );
    if (appointments) {
      dispatch(getAppointmentsAction(appointmentType, appointments));
    } else {
      dispatch(snackbarAlertAction(`Error loading appointments`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading appointments: ${e.message}`, 'error'));
    }
  }
};

export const getAllAppointmentThunk = ({
  appointmentType = 'appointments',
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'startDate',
  patient_role_id = null
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    const appointments: AppointmentSearchResults = await getHttp(
      `/appointment/clinician/${patient_role_id ? patient_role_id : userRoleId.id}?search=${search}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}`
    );
    if (appointments) {
      appointments.page = page;
      appointments.pageSize = pageSize;
      appointments.search = search;
      appointments.order = order;
      appointments.sort = sort;
      dispatch(getAppointmentsAction(appointmentType, appointments));
    } else {
      dispatch(snackbarAlertAction(`Error loading appointments`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading appointments: ${e.message}`, 'error'));
    }
  }
};

export const createAppointmentThunk = (patientInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    let newStr = patientInput.date + ' ' + patientInput.startTime
    const startDate = new Date(newStr);
    const newStartDate = startDate.toISOString()
    let newStr1 = patientInput.date + ' ' + patientInput.endTime
    const endDate = new Date(newStr1);
    const newEndDate = endDate.toISOString()
    const saveAppointment = await postHttp(`/appointment`, {
      patientId: patientInput.patientId,
      startDate: newStartDate,
      endDate: newEndDate,
      notes: patientInput.description,
      timezone: patientInput.timezone,
    });
    if (saveAppointment) {
      dispatch(setSuccessAction('appointmentSuccess', true));
      dispatch(snackbarAlertAction(`Appointment saved successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const updateAppointmentThunk = (appointmentId: string, patientInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    let newStr = patientInput.date + ' ' + patientInput.startTime
    const startDate = new Date(newStr);
    const newStartDate = startDate.toISOString()
    let newStr1 = patientInput.date + ' ' + patientInput.endTime
    const endDate = new Date(newStr1);
    const newEndDate = endDate.toISOString()
    const saveAppointment = await putHttp(`/appointment/${appointmentId}`, {
      patientId: patientInput.patientId,
      startDate: newStartDate,
      endDate: newEndDate,
      notes: patientInput.description,
      timezone: patientInput.timezone
    });
    if (saveAppointment) {
      dispatch(setSuccessAction('appointmentSuccess', true));
      dispatch(snackbarAlertAction(`Updated appointment successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating resources: ${e.message}`, 'error'));
    }
  }
};

export const deleteAppointmentThunk = (appointmentId: string, patientId: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const saveAppointment = await deleteHttp(`/appointment/${appointmentId}`);
    if (saveAppointment) {
      dispatch(setSuccessAction('appointmentSuccess', true));
      dispatch(snackbarAlertAction(`Delete appointment successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating resources: ${e.message}`, 'error'));
    }
  }
};

export const deleteAppointmentsThunk = (appointmentId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const result = await deleteHttp(`/appointment/${appointmentId}`);
    if (result) {
      dispatch(setSuccessAction('appointmentSuccess', true));
      dispatch(snackbarAlertAction(`Delete appointment successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating resources: ${e.message}`, 'error'));
    }
  }
};

export const getPatientFamilyThunk = (patientId: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setErrorAction('getCollectionsError', null));
    const patientFamily: any = await postHttp(`/user/patient/family-member-details`, {
      "patientId": patientId
    });
    dispatch(getPatientFamilyAction(patientFamily));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(getPatientFamilyAction(null));
      dispatch(setErrorAction('getCollectionsError', e.message));
    }
  }
};

export const getAllPatientFamilyThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    const patientFamilies: any = await getHttp(`/patient/getAllPatients?organization_id=${userRoleId.organization_id}&favourites=${false}`);
    dispatch(getAllPatientFamilyAction(patientFamilies.data));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getCollectionsError', e.message));
    }
  }
};

/** Patient portal - get all appointments */
export const getPatientAppointmentsThunk = ({
  order = 'DESC',
  page = 0,
  pageSize = 10,
  sort = 'startDate',
  givePatientAppointments = false,
  patient_role_id = null,
  organization_id = null
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    const appointments: any = await getHttp(
      `/appointment/getAssignedAppointments?user_role_id=${patient_role_id ? patient_role_id : userRoleId.id}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}&org_id=${organization_id ? organization_id : userRoleId.organization_id}&givePatientAppointments=${givePatientAppointments}`
    );
    if (appointments) {
      appointments.page = page;
      appointments.pageSize = pageSize;
      appointments.givePatientAppointments = givePatientAppointments;
      appointments.order = order;
      appointments.sort = sort;
      dispatch(getAppointmentsAction('appointments', appointments));
    } else {
      dispatch(snackbarAlertAction(`Error loading appointments`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading appointments: ${e.message}`, 'error'));
    }
  }
};

export const assignAppointmentToUsersThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await putHttp(`/appointment/updateSharingSettings`, {
      "appointment_id": data.id,
      "user_role_ids": data.user_role_ids,
      "patient_id": data.patient_id,
      "clinician_id": data.clinician_id
    });
    dispatch(setSuccessAction('appointmentSuccess', true));
    dispatch(snackbarAlertAction(`Appointment assigned successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Failed to assigned appointment`, 'error'));
    }
  }
};
