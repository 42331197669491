import { Box, Avatar, makeStyles, Theme, Typography, Popover, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../components/Table';
import { GetReferralsProps } from '../../../redux/thunk/thunks';
import { UuidType } from '../../../shared/domain';
import { ROLES } from '../../../shared/globals';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { NewReferralDialog } from './NewReferralDialogue';
import { ReferralProfile } from '../ReferralProfile/ReferralProfilePage';
import { setEditReferralAction } from '../../../redux/data/data-actions';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { sentReferralInviteThunk } from '../../../redux/thunk/thunks';

interface ReferralTableProps {
  ReferralId?: UuidType;
  referralsData: any | null;
  selectedReferrals?: UuidType[];
  loadReferrals: (getBundlesProps: GetReferralsProps) => void;
  setSelectedReferrals?: (selectedReferral: UuidType[]) => void;
  isReferralLibrary: Boolean;
  filtersList: any;
  handleDeleteReferral: (info: any) => void;
  viewReferralOpen: (open: any) => void;
  viewReferralDialogueOpen: boolean | false;
}

export const ReferralsTable = ({
  referralsData,
  selectedReferrals,
  loadReferrals,
  setSelectedReferrals,
  isReferralLibrary,
  filtersList,
  handleDeleteReferral,
  viewReferralOpen,
  viewReferralDialogueOpen
}: ReferralTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null)
  const [referralDialogueOpen, setReferralDialogueOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isDelete, setDeleteOpen] = React.useState(false);
  const [referralInfo, setReferralInfo] = useState<any>();
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'city',
    order: 'desc'
  });
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const headCells: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT'
    },
    {
      id: 'city',
      label: 'LOCATION'
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells1: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT'
    },
    {
      id: 'org_id',
      label: 'TYPE'
    },
    {
      id: 'city',
      label: 'LOCATION'
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];

  const refActions = async (event: any, referral: any) => {
    setAnchorEl(event.currentTarget);
    setReferralInfo(referral);
    await dispatch(setEditReferralAction(referral));
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSetEditReferral = async (referral: any) => {
    await dispatch(setEditReferralAction(referral));
  }

  const editReferral = async () => {
    viewReferralOpen(true);
    setAnchorEl(null);
  }

  const deleteReferral = async () => {
    setLoading(true);
    await handleDeleteReferral([referralInfo.id]);
    setLoading(false);
    setDeleteOpen(false);
  }
  const handelOpenDelete = () => {
    setDeleteOpen(true);
  }
  const handelCloseDelete = () => {
    setDeleteOpen(false);
  }
  const sentInvite = () => {
    dispatch(sentReferralInviteThunk(referralInfo.referral_id));
  }

  const rowCells = referralsData?.data.map((referral: any) => {
    const nameLink = (<Box>
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} className={classes.referralName} onClick={(e) => { handleViewReferralDialogueOpen(referral); handleSetEditReferral(referral) }}>
        {referral.first_name + ' ' + referral.last_name}
      </Typography>
      <Typography color={'textSecondary'} variant="h5">
        {referral.title}
      </Typography>
    </Box>
    );
    const specialties = <Box>
      {referral.specialities?.map((specialty: any, index: number) => {
        return (
          <>
            <Typography color={'textSecondary'} variant="h5" className={classes.tableBox}>
              {specialty.title}
            </Typography>
          </>
        );
      })
      }
    </Box>
    const Actions = (
      <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, referral)} />
        <Popover
          id={'simple-popover'}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box>
            <Typography className={classes.actionTypography} onClick={editReferral}>Edit Referral</Typography>
            <Divider />
            {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && referralInfo && referralInfo.org_id && <Typography color='error' className={classes.actionTypography} onClick={() => { handelOpenDelete(); setAnchorEl(null) }}>Delete Referral</Typography>}
            {loggedInUser && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role) && <Typography color='error' className={classes.actionTypography} onClick={() => { handelOpenDelete(); setAnchorEl(null) }}>Delete Referral</Typography>}
            <Divider />
            {referralInfo && referralInfo.status !== 'ACTIVE' && <Typography color='primary' className={classes.actionTypography} onClick={() => { sentInvite(); setAnchorEl(null) }}>Resend Invite</Typography>}
          </Box>
        </Popover>
      </>
    );
    const contactDetails = (
      <>
        <Typography color={'textSecondary'} variant="h5">
          {referral.email}
        </Typography>
        <Typography color={'textSecondary'} variant="h5">
          {referral.mobile_phone}
        </Typography>
      </>
    );
    const location = (
      <>
        <Typography color={'textSecondary'} variant="h5">
          {referral.city && referral.state ? ((referral.city ? (referral.city + ', ') : '') + (referral?.state)) : '-'}
        </Typography>
        <Typography color={'textSecondary'} variant="h5">
          {referral.zip}
        </Typography>
      </>
    );
    const type = referral.org_id ? <Avatar style={{ width: 32, height: 32 }}>C</Avatar> : <Avatar style={{ width: 32, height: 32, background: '#0060b2' }}>B</Avatar>;

    let rowCell: RowCell = {};

    if (isReferralLibrary) {
      rowCell = [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? {
        first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
        specialities: {
          component: specialties,
          value: '-'
        },
        email: {
          component: contactDetails,
          value: Object.create({ email: referral.email, phone: referral.mobile_phone })
        },
        org_id: { component: type, value: '-' },
        city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
        action: {
          component: Actions
        }
      } :
        {
          first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
          specialities: {
            component: specialties,
            value: '-'
          },
          email: {
            component: contactDetails,
            value: Object.create({ email: referral.email, phone: referral.mobile_phone })
          },
          city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
          action: {
            component: Actions
          }
        };
    } else {
      rowCell = [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? {
        first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
        email: {
          component: contactDetails,
          value: Object.create({ email: referral.email, phone: referral.mobile_phone })
        },
        specialities: {
          component: specialties,
          value: '-'
        },
        org_id: { component: type, value: '-' },
        city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
        action: {
          align: 'right',
          component: Actions
        }
      } :
        {
          first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
          email: {
            component: contactDetails,
            value: Object.create({ email: referral.email, phone: referral.mobile_phone })
          },
          specialities: {
            component: specialties,
            value: '-'
          },
          city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
          action: {
            align: 'right',
            component: Actions
          }
        }
    }

    return Object.assign(rowCell, { content: referral });
  });

  const handleNextButton = async () => {
    if (
      referralsData &&
      referralsData?.page >= 0 &&
      referralsData?.page < referralsData?.totalCount / referralsData?.pageSize - 1
    ) {
      await loadReferrals({
        search: referralsData?.search,
        page: referralsData?.page + 1,
        pageSize: referralsData?.pageSize,
        sort: referralsData?.sort,
        order: referralsData?.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
        referral_type: referralsData?.referral_type
      });
    }
  };
  const handlePrevButton = async () => {
    if (referralsData && referralsData?.page > 0) {
      await loadReferrals({
        search: referralsData?.search,
        page: referralsData?.page - 1,
        pageSize: referralsData?.pageSize,
        sort: referralsData?.sort,
        order: referralsData?.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
        referral_type: referralsData?.referral_type
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize = e.target.value as number;

    if (referralsData && pageSize > 0) {
      loadReferrals({
        page: 0,
        pageSize: pageSize,
        search: referralsData?.search,
        sort: referralsData?.sort,
        order: referralsData?.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
        referral_type: referralsData?.referral_type
      });
    }
  };

  const pagination: Pagination | undefined = referralsData
    ? {
      contentName: 'Referrals',
      page: referralsData?.page,
      pageSize: referralsData?.pageSize,
      total: referralsData?.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadReferrals({
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: referralsData?.pageSize,
      search: referralsData?.search,
      sort,
      favourites: referralsData?.favourites,
      filters: referralsData?.filters,
      referral_type: referralsData?.referral_type
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const handleViewReferralDialogueClose = async () => {
    await loadReferrals({
      page: referralsData.page,
      pageSize: referralsData.pageSize,
      search: referralsData?.search,
      sort: referralsData?.sort,
      order: referralsData?.order,
      favourites: referralsData?.favourites,
      filters: referralsData?.filters,
      referral_type: referralsData?.referral_type
    });
    viewReferralOpen(false);
  };

  const handleChangeFavorite = (value: boolean) => {
    referralInfo.isFavourite = value;
    setReferralInfo(referralInfo);
  }

  const handleViewReferralDialogueOpen = (referralInfo: any) => {
    viewReferralOpen(true);
    setReferralInfo(referralInfo);
  };

  const handleReferralDialogueClose = () => {
    setReferralDialogueOpen(true);
  };

  return (
    <>
      {referralDialogueOpen && (
        <NewReferralDialog open={referralDialogueOpen} handleClose={handleReferralDialogueClose}
          info={referralInfo} edit={false} />
      )}
      {viewReferralDialogueOpen && (
        <ReferralProfile open={viewReferralDialogueOpen} handleClose={handleViewReferralDialogueClose} viewInfo={referralInfo} handleChangeFavorite={handleChangeFavorite} />
      )}
      {isDelete &&
        <ConfirmationDialog
          loading={loading}
          message='type "DELETE" to confirm.'
          open={isDelete}
          title='Are you sure you want to delete? Please'
          handleClose={handelCloseDelete}
          handleConfirmClick={() => deleteReferral()}
        />
      }
      <Table
        data={referralsData?.data}
        headCells={[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? headCells1 : headCells}
        loading={!referralsData?.data}
        pagination={pagination}
        rowCells={rowCells}
        selection={
          selectedReferrals && setSelectedReferrals
            ? {
              selectedContents: selectedReferrals,
              setSelectedContents: (selected: UuidType[]) => setSelectedReferrals(selected)
            }
            : undefined
        }
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  },
  referralName: {
    display: 'flex'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  InfoIcon: {
    fontSize: 16,
    marginLeft: 6
  },
  color: {
    color: '#0060B2'
  },
  tableBox: {
    padding: '2px 8px',
    marginRight: '5px',
    minWidth: '80px',
  },
  actionTypography: {
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
}));
