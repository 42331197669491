import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Tooltip, Popover, Paper, Divider, Box } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { UuidType } from '../../../../shared/domain';
import { deleteAppointmentsThunk, getSharedUsersThunk } from '../../../../redux/thunk/thunks';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../components/Table';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { NewAppointment } from './NewAppointment';
import moment from 'moment'
import { ROLES } from '../../../../shared/globals';
import { ShareToUser } from '../../../Resources/AssignPatient/ShareToUser';
import { useRouteMatch } from 'react-router-dom';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    padding: '0px 10px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface AppointmentTableProps {
  appointmentId?: UuidType;
  appointmentsData: any | null;
  selectedAppointments?: UuidType[];
  loadAppointments: (GetAppointmentsProps: any) => void;
  setSelectedAppointment?: (selectedResources: UuidType[]) => void;
}

export const AppointmentsTable = ({
  appointmentsData,
  selectedAppointments,
  loadAppointments,
  setSelectedAppointment
}: AppointmentTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organization_id: any = sessionStorage.getItem('pat_org_id');
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const { path } = useRouteMatch();
  const split = path.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];
  const [newAppointmentDialogueOpen, setNewAppointmentDialogueOpen] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [popuerInfo, setPopuerInfo] = useState<any>(null);
  const [openSharing, setOpenSharing] = useState(false);
  const [data, setData] = useState<any>(null);
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'startDate',
    order: 'desc'
  });

  const refActions = (event: any, amtInfo: any) => {
    setAnchorEl(event.currentTarget);
    setPopuerInfo(amtInfo)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const headCells: HeadCell[] = [
    {
      align: 'left',
      id: 'startDate',
      label: 'DATE & TIME'
    },
    {
      align: 'left',
      id: 'assigned_by_name',
      label: 'SCHEDULED WITH'
    },
    {
      align: 'left',
      id: 'note',
      label: 'NOTES',
      isSortDisabled: true
    },
    {
      align: 'right',
      id: 'action',
      label: 'SHARING'
    },
  ];

  const handelShareUser = async (data: any) => {
    await dispatch(getSharedUsersThunk({ giveOnlyActiveUsers: true, user_role_id: patient_role_id, organization_id: organization_id }));
    setData(data);
    setOpenSharing(true);
  }

  const handelClose = () => {
    setOpenSharing(false);
    setData(null);
  }

  const rowCells = appointmentsData?.data && appointmentsData?.data.map((appointment: any) => {
    const appDate = new Date(appointment.startDate);
    const appDate2 = new Date(appointment.endDate);

    const nameLink =
      <Box>
        <Typography className={classes.nameLink} style={{ cursor: 'pointer' }} color='primary' variant='h5' onClick={(e) => handleNewAppointmentDialogueOpen(appointment)}>
          {moment(appDate).format("MMM Do YYYY, h:mm a") + ' - ' + moment(appDate2).format("h:mm a")}
        </Typography>
        <Typography style={{ color: '#8e8e8e', fontSize: '12px' }}>{appointment.timezone}</Typography>
      </Box>;
    const notes = <HtmlTooltip title={appointment.notes}>
      <Typography style={{ margin: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '400px' }}>{appointment.notes}</Typography>
    </HtmlTooltip>
    const sharedUser = (
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handelShareUser(appointment)}>
        {appointment?.sharedUsers?.length + ' Users'}
      </Typography>
    );
    const rowCell: RowCell = {
      startDate: {
        align: 'left',
        component: nameLink
      },
      assigned_by_name: {
        align: 'left',
        value: appointment.assigned_by_name
      },
      note: {
        align: 'left',
        component: notes
      },
      action: {
        align: 'right',
        component: sharedUser,
        value: appointment?.sharedUsers?.length + ' Users'
      }
    }

    return Object.assign(rowCell, { content: appointment });
  });

  const handleNextButton = async () => {
    if (
      appointmentsData &&
      appointmentsData.page >= 0 &&
      appointmentsData.page < appointmentsData.totalCount / appointmentsData.pageSize - 1
    ) {
      await loadAppointments({
        search: appointmentsData.search,
        page: appointmentsData.page + 1,
        pageSize: appointmentsData.pageSize,
        sort: appointmentsData.sort,
        order: appointmentsData.order,
        givePatientAppointments: appointmentsData.givePatientAppointments
      });
    }
  };
  const handlePrevButton = async () => {
    if (appointmentsData && appointmentsData.page > 0) {
      await loadAppointments({
        search: appointmentsData.search,
        page: appointmentsData.page - 1,
        pageSize: appointmentsData.pageSize,
        sort: appointmentsData.sort,
        order: appointmentsData.order,
        givePatientAppointments: appointmentsData.givePatientAppointments
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize = e.target.value as number;

    if (appointmentsData && pageSize > 0) {
      loadAppointments({
        page: 0,
        pageSize: pageSize,
        search: appointmentsData.search,
        sort: appointmentsData.sort,
        order: appointmentsData.order,
        givePatientAppointments: appointmentsData.givePatientAppointments
      });
    }
  };

  const pagination: Pagination | undefined = appointmentsData
    ? {
      contentName: 'Appointments',
      page: appointmentsData.page,
      pageSize: appointmentsData.pageSize,
      total: appointmentsData.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadAppointments({
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: appointmentsData?.pageSize,
      search: appointmentsData?.search,
      sort,
      givePatientAppointments: appointmentsData.givePatientAppointments
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const handleNewAppointmentDialogueClose = () => {
    setNewAppointmentDialogueOpen(false);
  };

  const handleNewAppointmentDialogueOpen = (appointment: any) => {
    setNewAppointmentDialogueOpen(true);
    setAppointmentInfo(appointment);
    setAnchorEl(null);
  };

  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
    setAnchorEl(null);
  };
  const handleDeleteAppointments = async () => {
    setDeleteLoading(true);
    await dispatch(deleteAppointmentsThunk(popuerInfo.id));
    setDeleteFormsConfirmationDialogOpen(false);
    setDeleteLoading(false);
  }

  return (
    <>
      {newAppointmentDialogueOpen && (
        <NewAppointment open={newAppointmentDialogueOpen} handleClose={handleNewAppointmentDialogueClose}
          patient={null} appointmentInfo={appointmentInfo} edit={true} />
      )}
      {deleteFormsConfirmationDialogOpen &&
        <ConfirmationDialog
          loading={deleteLoading}
          message='type "DELETE" to confirm.'
          open={deleteFormsConfirmationDialogOpen}
          title='Are you sure you want to delete? Please'
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteAppointments()}
        />
      }
      <Table
        data={appointmentsData?.data}
        headCells={headCells}
        loading={!appointmentsData?.data}
        pagination={pagination}
        rowCells={rowCells}
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
      {openSharing && <ShareToUser handelClose={handelClose} data={data} section={'appointment'} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  actionTypography: {
    padding: '10px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
}));

