import React, { useState, useEffect } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, TextField, Select, InputBase  } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../../components/Dialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { RootState } from '../../../../redux/redux-store';
import { createAppointmentThunk, updateAppointmentThunk, deleteAppointmentThunk } from '../../../../redux/thunk/thunks';
import { concatName } from '../../../../shared/utils';
import { timezoneNames, ZonedDate } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/all";
import { withStyles } from '@material-ui/core/styles';

const timezones = timezoneNames()
  .filter((l) => l.indexOf("/") > -1)
  .sort((a, b) => a.localeCompare(b));

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '6px 26px 6px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);
  

interface NewAppointmentDialogProps {
  open: boolean;
  handleClose: () => void;
  patient: any;
  appointmentInfo: any;
  edit: boolean
}

interface appointmentFormValues {
  patientName: string;
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  id: string;
}

export const NewAppointment = ({ open, handleClose, patient, appointmentInfo, edit }: NewAppointmentDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [disDate, setDisabledDate] = useState<any>();
  const [isSave, setIsSave] = useState(false);
  const [timezone, setTimezone] = useState<any>('America/New_York')
  const [initialValues, setInitialValues] = useState<appointmentFormValues>({
    startTime: '',
    patientName: patient
      ? concatName({
        firstName: patient.first_name,
        lastName: patient.last_name,
        fallback: patient.email ?? '<No Patient Name Available>'
      })
      : '',
    id: patient && patient.id,
    date: '',
    endTime: '',
    description: ''
  });

  const message = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
  );

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + new Date(newDate).getDate()).slice(-2));
    if (edit && initialValues.description === "") {
      const appDate = new Date(appointmentInfo.startDate);
      const appDate2 = new Date(appointmentInfo.endDate);
      let startTime = (("0" + appDate.getHours()).slice(-2)) + ':' + (("0" + appDate.getMinutes()).slice(-2));
      let endTime = (("0" + appDate2.getHours()).slice(-2)) + ':' + (("0" + appDate2.getMinutes()).slice(-2));
      let firstName: string = appointmentInfo.patient_first_name;
      let lastName: string = appointmentInfo.patient_last_name;
      setInitialValues({
        patientName: appointmentInfo
          ? concatName({
            firstName: firstName,
            lastName: lastName,
            fallback: appointmentInfo.email ?? '<No Patient Name Available>'
          })
          : '',
        id: appointmentInfo.patientId,
        date: appDate.getFullYear() + '-' + (("0" + (appDate.getMonth() + 1)).slice(-2)) + '-' + ("0" + appDate.getDate()).slice(-2),
        startTime: startTime,
        endTime: endTime,
        description: appointmentInfo.notes
      });
    }
  }, [edit]);

  useEffect(() => {
    if (message && message.length > 0) {
      setIsSave(false);
      handleClose();
    }
  }, [message]);

  const submitAppointment = (values: appointmentFormValues) => {
    setIsSave(true);
    dispatch(createAppointmentThunk(values));
  };

  const editAppointment = (values: appointmentFormValues) => {
    setIsSave(true);
    dispatch(updateAppointmentThunk(appointmentInfo.id, values));
  };

  const deleteAppointment = () => {
    setIsSave(true);
    dispatch(deleteAppointmentThunk(appointmentInfo.id, initialValues.id));
  };

  const patientInfoSchema = Yup.object().shape({
    patientName: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    startTime: Yup.string().required('Required'),
    endTime: Yup.string().required('Required'),
  });

  const handleTimezoneChange = (event: any) => {
    setTimezone(event.target.value);
  };

  return (
    <Dialog handleClose={handleClose} open={open} >
      <Box display='flex' flexDirection='column' className={classes.box}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={edit ? editAppointment : submitAppointment}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={patientInfoSchema}
        >
          {({ errors, isSubmitting, setSubmitting, touched }) => {

            return (
              <Form style={{ width: '450px' }}>
                <Box pb={5}>
                  <Box pb={0.5}>
                    <Typography color="textSecondary" variant="h1">
                      {edit ? 'Appointment Details' : 'Add an Appointment'}
                    </Typography>
                  </Box>
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Select User
                    </Typography>
                  </Box>

                  <Field
                    as={TextField}
                    className={classes.textField}
                    name="patientName"
                    disabled={true}
                    size="small"
                    type=""
                    variant="outlined"
                  />

                  {errors.patientName && touched.patientName ? (
                    <Typography color="error" variant="subtitle2">
                      {errors.patientName}
                    </Typography>
                  ) : null}
                </Box>

                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Select Time Zone
                    </Typography>
                  </Box>
                  <Select variant="outlined" input={<BootstrapInput />}
                    className={classes.textField}
                    onChange={handleTimezoneChange}
                    value={timezone}
                  >
                    {timezones.map((zone, i) => (
                      <option key={zone} value={zone}>{zone}</option>
                    ))}
                  </Select>
                </Box>

                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Date
                    </Typography>
                  </Box>

                  <Field
                    as={TextField}
                    className={classes.textField}
                    name="date"
                    size="small"
                    type="date"
                    variant="outlined"
                    InputProps={{ inputProps: { min: disDate } }}
                  />

                  {errors.date && touched.date ? (
                    <Typography color="error" variant="subtitle2">
                      {errors.date}
                    </Typography>
                  ) : null}
                </Box>

                <Box pb={2.5}>
                  <Grid alignItems="center" container justify="center" spacing={3}>
                    <Grid item xs={6}>
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Start Time
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        className={classes.textField}
                        name="startTime"
                        size="small"
                        type="time"
                        variant="outlined"
                      />

                      {errors.startTime && touched.startTime ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.startTime}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          End Time
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        className={classes.textField}
                        name="endTime"
                        type="time"
                        size="small"
                        variant="outlined"
                      />

                      {errors.endTime && touched.endTime ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.endTime}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>

                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Description
                    </Typography>
                  </Box>

                  <Field
                    as={TextField}
                    className={classes.textField}
                    name="description"
                    multiline
                    rows={5}
                    size="small"
                    type="text"
                    variant="outlined"
                  />

                  {errors.description && touched.description ? (
                    <Typography color="error" variant="subtitle2">
                      {errors.description}
                    </Typography>
                  ) : null}
                </Box>

                <Box pb={2}>
                  <Grid container>
                    <Grid item xs={3} style={{ textAlign: 'left' }}>
                      <LoadingButton
                        className={classes.secondaryButton}
                        variant="contained"
                        aria-label="Submit"
                        onClick={(e) => handleClose()}
                      >
                        Cancel
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: 'right' }}>
                      {edit ?
                        <React.Fragment><LoadingButton color='secondary'
                          className={classes.deleteButton}
                          disabled={isSave}
                          onClick={(e) => deleteAppointment()}
                          variant="contained"
                        >
                          Delete Appointment
                        </LoadingButton>
                          <LoadingButton color="primary"
                            disabled={isSave}
                            className={classes.primaryButton}
                            type="submit"
                            variant="contained"
                            aria-label="sign in"
                          >
                            Edit Appointment
                          </LoadingButton></React.Fragment> :
                        <LoadingButton color="primary"
                          disabled={isSave}
                          className={classes.primaryButton}
                          type="submit"
                          variant="contained"
                          aria-label="sign in"
                        >
                          Add Appointment
                        </LoadingButton>}
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },

  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  deleteButton: {
    marginRight: '10px',
    background: '#ce3a19',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ce3a19'
    }
  },
  inputField: {
    maxWidth: '28rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  },
  textField: {
    width: '100%'
  },
  textAlign: {
    textAlign: 'center'
  },
  box: {
    padding: '30px'
  },
}));