import { Box, makeStyles, Paper, TextField, Theme, Typography, MenuItem, Select, FormControl, ListItemText, ListItemAvatar, ListItem, Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  getLoggedInUserThunk, updateProfile, getStateThunk, sentOTPThunk, getUnitThunk, getDepartmentThunk,
  verifyOTPThunk, deleteUsersAndLogoutThunk, getSecondarySiteAdmin, newSiteAdminThunk
} from '../../../../redux/thunk/thunks';
import { RootState } from '../../../../redux/redux-store';
import { Person } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Dialog } from '../../../../components/Dialog';
import * as Yup from "yup";
import { LoadingButton } from '../../../../components/LoadingButton';
import { colors } from '../../../../styling/colors';
import { LoadingOrError } from '../../../../components/LoadingOrError';
import { ChangePasswordDialogue } from './ChangePasswordDialogue';
import { ROLES } from '../../../../shared/globals';
import { otpVerificationAction } from '../../../../redux/data/data-actions';
import { setSuccessAction } from '../../../../redux/display/display-actions';

export const MyProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [disDate, setDisabledDate] = useState<any>();
  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [isOpen, setIsOpen] = useState(false);
  const [isSSOOpen, setIsSSOOpen] = useState(false);
  const [input, setInput] = useState('');
  const [otp, setOTP] = useState('');
  const [siteAdmin, setSiteAdmin] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [siteAdminPopup, setOpenSiteAdminPopup] = useState(false);
  const [siteAdminSSOPopup, setOpenSiteAdminSSOPopup] = useState(false);

  const [zip, setZip] = useState<any>(user ? user.zip : '');
  const viewData: any = {
    role: user ? user?.role?.replace(/_/g, " ") : '',
    emails: user?.email || '',
    patient_id: user?.patient_id || '',
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    mobilePhone: user?.mobile_phone || '',
    birthDate: user?.birth_date || '',
    homeAddress: user?.home_address || '',
    citys: user?.city || '',
    state: user?.state || '',
    zips: user?.zip || '',
    id: user?.id || '',
    notes: user?.notes || '',
    unit_id: user?.unit_id || null,
    department_id: user?.department_id || null,
  }

  const otpVerification: any = useSelector((state: RootState) => state.DATA_REDUCER.otpVerification);

  const loadingUser = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getLoggedInUserLoading
  );
  const loadingUserError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getLoggedInUserError
  );
  const newPrimarySiteAdmin = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.newPrimarySiteAdmin
  );

  useEffect(() => {
    if (newPrimarySiteAdmin) {
      dispatch(setSuccessAction('newPrimarySiteAdmin', false));
      setOpenSiteAdminPopup(false);
      setIsEdit(false);
      setSiteAdmin('');
      setInput('');
      setSiteAdmin('');
      setOTP('');
      setOpenSiteAdminSSOPopup(false);
      setIsSSOOpen(false);
      dispatch(otpVerificationAction(null));
    }
  }, [newPrimarySiteAdmin])

  const initialValues: any = {
    role: user ? user.role.replace(/_/g, " ") : '',
    email: user?.email || '',
    patient_id: user?.patient_id || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    mobile_phone: user?.mobile_phone || '',
    birth_date: user?.birth_date || '',
    home_address: user?.home_address || '',
    city: user?.city || '',
    state: user?.state || '',
    zip: user?.zip || '',
    id: user?.id || '',
    userId: user?.user_id || '',
    user_role_id: user?.user_role_id || '',
    notes: user?.notes || '',
    unit_id: user?.unit_id || null,
    department_id: user?.department_id || null,
  }
  const departmentList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.departmentList
  );
  const unitList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.unitList
  );
  const stateList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.stateList
  );
  const secondarySiteAdmins: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.secondarySiteAdmins
  );
  useEffect(() => {
    dispatch(getLoggedInUserThunk())
    dispatch(getStateThunk());
    dispatch(getUnitThunk());
    dispatch(getDepartmentThunk());
  }, [])

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    // const birth = values.birth_date !== '' ? (new Date(values.birth_date).getFullYear() + '-' + (("0" + (new Date(values.birth_date).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(values.birth_date).getDate())).slice(-2)) : ''
    const data = values;
    data.birth_date = values.birth_date;
    data.zip = zip ? zip : '';
    data.zip = zip ? zip : '';
    await dispatch(updateProfile(data));
    actions.setSubmitting(false);
    setIsEdit(false);
  };

  const profileSchema = Yup.object().shape({
    first_name: Yup.string().required("Required").max(50, ''),
    last_name: Yup.string().required("Required").max(50, ''),
    city: Yup.string().max(50, ''),
    state: Yup.string().max(50, ''),
    zip: Yup.string().min(5, "Too Short!").max(5, ''),
    mobile_phone: Yup.string().min(10, "Too Short!").max(12, ''),
    home_address: Yup.string().max(100, ''),
    notes: Yup.string().max(250, ''),
    email: Yup.string().email("Enter Valid Email").required("Please enter Email"),
  });

  const handelCancel = () => {
    setIsEdit(false)
    setIsCancel(isCancel ? false : true)
  }

  const handelEdit = (value: boolean) => {
    setIsEdit(value);
    if (![ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role)) {
      loggedInUser && loggedInUser.organization_id && dispatch(getSecondarySiteAdmin());
    }
  }

  const deletePopup = async () => {
    if (loggedInUser && loggedInUser?.is_sso_user) {
      dispatch(sentOTPThunk({ email: user?.email, user_role_id: loggedInUser?.id, type: 'DELETE_ACCOUNT' }));
      setIsSSOOpen(true);
    } else {
      setIsOpen(true);
    }
  }

  const handleOpenSiteAdminPopup = () => {
    if (loggedInUser && loggedInUser?.is_sso_user) {
      dispatch(sentOTPThunk({ email: user?.email, user_role_id: loggedInUser?.id, type: 'DESIGNATE_PSITEADMIN' }));
      setOpenSiteAdminSSOPopup(true)
    } else {
      setOpenSiteAdminPopup(true)
    }
  }

  const resendOTP = (type: any) => {
    dispatch(sentOTPThunk({ email: user?.email, user_role_id: loggedInUser?.id, type: type }));
  }

  const handleClose = () => {
    setIsOpen(false);
    setIsSSOOpen(false);
    setOpenSiteAdminPopup(false);
    setError(false);
    setSiteAdmin('');
    setOTP('');
    setOpenSiteAdminSSOPopup(false);
    dispatch(otpVerificationAction(null));
  }

  const deleteProfile = async () => {
    if (loggedInUser && loggedInUser?.is_sso_user) {
      dispatch(verifyOTPThunk({ otp: otp, user_role_id: loggedInUser?.id, type: 'DELETE_ACCOUNT' }));
    } else {
      await dispatch(deleteUsersAndLogoutThunk(user?.user_role_id, input));
    }
  }

  const newSiteAdmin = async () => {
    if (loggedInUser && loggedInUser?.is_sso_user) {
      dispatch(verifyOTPThunk({ otp: otp, user_role_id: loggedInUser?.id, type: 'DESIGNATE_PSITEADMIN', organization_id: loggedInUser && loggedInUser.organization_id, new_primary_site_admin_id: siteAdmin }));
    } else {
      if (siteAdmin !== '' && input !== '') {
        setLoading(true);
        setError(false);
        const data = {
          organization_id: loggedInUser && loggedInUser.organization_id,
          new_primary_site_admin_id: siteAdmin,
          current_site_admin_password: input
        }
        await dispatch(newSiteAdminThunk(data));
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    }
  }

  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 12)
    } else {
      return null
    }
  }

  const isNumber = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 6) {
      setOTP(value)
    } else {
      value.length > 6 || value.length == 0 && setOTP('')
    }
  }

  const handelChange = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 6) {
      setZip(value)
    } else {
      value.length > 6 || value.length == 0 && setZip('')
    }
  }

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 1 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 2 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 3 && value.length > 100) {
      setIsError(true); setInputs(no);
    } else if (no === 4 && value.length > 250) {
      setIsError(true); setInputs(no);
    } else {
      setIsError(false); setInputs(no);
    }
  }

  return (
    <>
      {changePasswordDialogOpen && user && (
        <ChangePasswordDialogue
          open={changePasswordDialogOpen}
          id={user && user.user_id}
          handleClose={() => {
            setChangePasswordDialogOpen(false);
          }}
        />
      )}
      <Paper>
        {isEdit === false && <Box
          className={classes.topBox1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2.5}
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" px={4}>
            <Typography variant="h2">My Profile</Typography>
            <Box display="flex" justifyContent="space-between">
              <LoadingButton
                className={classes.primaryButton}
                color='primary'
                variant="contained"
                onClick={(e) => handelEdit(true)}
              >
                Edit
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        }
        {loadingUser || loadingUserError ? (
          <LoadingOrError
            errorMsg={loadingUserError}
            loading={true}
            loadingMsg="Loading User Profile"
            noElevation
          />
        ) : (isEdit ?
          <Box pb={4} pt={3} px={4} display="flex">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={profileSchema}
            >
              {({ errors, touched, isSubmitting, values }) => {
                return (
                  <Box>
                    <Form className={classes.formCont}>
                      {isEdit && <Box
                        className={classes.topBox}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        py={2.5}
                      >
                        <Box alignItems="center" display="flex" justifyContent="space-between">
                          <Typography variant="h2">My Profile</Typography>
                          <Box display="flex" justifyContent="space-between">
                            <LoadingButton
                              className={classes.secondaryButton}
                              color="secondary"
                              variant="contained"
                              onClick={(e) => handelCancel()}
                            >
                              Cancel
                            </LoadingButton>
                            <LoadingButton color='primary'
                              className={classes.primaryButton}
                              variant="contained"
                              type='submit'
                              disabled={isSubmitting}
                            >
                              Save
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                      }
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              First Name*
                            </Typography>
                          </Box>

                          <Field fullWidth style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 0)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            disabled={isEdit ? false : true}
                            name="first_name"
                            size="small"
                            type="text"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.first_name && touched.first_name && errors.first_name}
                            </Typography>
                            <Typography style={{ color: values.first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.first_name?.length === 51 ? 50 : values.first_name?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Last Name*
                            </Typography>
                          </Box>

                          <Field fullWidth style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 1)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            disabled={isEdit ? false : true}
                            name="last_name"
                            size="small"
                            type="text"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.last_name && touched.last_name && errors.last_name}
                            </Typography>
                            <Typography style={{ color: values.last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.last_name?.length === 51 ? 50 : values.last_name?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        {loggedInUser && loggedInUser.role === ROLES.PATIENT ?
                          <Box flexGrow="1">
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Patient ID*
                              </Typography>
                            </Box>

                            <Field
                              as={TextField}
                              disabled
                              name="patient_id"
                              size="small"
                              type="text"
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Box> :
                          <Box flexGrow="1">
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Role*
                              </Typography>
                            </Box>

                            <Field
                              as={TextField}
                              disabled
                              name="role"
                              size="small"
                              type="text"
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Box>
                        }
                      </Box>

                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Phone Number
                            </Typography>
                          </Box>

                          <Field
                            as={TextField}
                            disabled={isEdit ? false : true}
                            name="mobile_phone"
                            size="small"
                            onInput={(e: any) => {
                              e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                            }}
                            type="tel"
                            variant="outlined"
                            style={{ width: '100%' }}
                          />
                          {errors.mobile_phone && touched.mobile_phone ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.mobile_phone}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Email Address*
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          disabled={true}
                          className={classes.textField}
                          name="email"
                          size="small"
                          variant="outlined"
                        />
                        {errors.email && touched.email ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Date of Birth
                            </Typography>
                          </Box>

                          <Field
                            as={TextField}
                            disabled={isEdit ? false : true}
                            name="birth_date"
                            format="MM/dd/yyyy"
                            size="small"
                            type="date"
                            variant="outlined"
                            style={{ width: '100%' }}
                            InputProps={{ inputProps: { max: disDate } }}
                          />
                          {errors.birth_date && touched.birth_date ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.birth_date}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>

                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Address
                            </Typography>
                          </Box>

                          <Field fullWidth style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 3)}
                            as={TextField}
                            inputProps={{ maxLength: 101 }}
                            disabled={isEdit ? false : true}
                            name="home_address"
                            size="small"
                            type="text"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.home_address && touched.home_address && errors.home_address}
                            </Typography>
                            <Typography style={{ color: values.home_address?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.home_address?.length === 101 ? 100 : values.home_address?.length}/100
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              City
                            </Typography>
                          </Box>

                          <Field fullWidth style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 2)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            disabled={isEdit ? false : true}
                            name="city"
                            size="small"
                            type="text"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {''}
                            </Typography>
                            <Typography style={{ color: values.city?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.city?.length === 51 ? 50 : values.city?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              State
                            </Typography>
                          </Box>

                          <Field disabled={isEdit ? false : true} as={Select} name="state" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {stateList && stateList.length > 0 && stateList.map((item: any) => {
                              return <MenuItem value={item.state}>{item.state}</MenuItem>
                            })}
                          </Field>

                          {errors.state && touched.state ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.state}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Zip Code
                            </Typography>
                          </Box>

                          <Field
                            as={TextField}
                            value={zip} onChange={(e: any) => handelChange(e.target.value)}
                            disabled={isEdit ? false : true}
                            name="zip"
                            size="small"
                            variant="outlined"
                            style={{ width: '100%' }}
                          />
                          {errors.zip && touched.zip ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.zip}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                      {loggedInUser && !loggedInUser?.is_sso_user && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ? null :
                        <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                          {isEdit && loggedInUser.is_sso_user !== true && <Box flexGrow="1" alignSelf="flex-end" display="flex" justifyContent="end">
                            <LoadingButton
                              className={classes.openDialogoeBtn}
                              onClick={() => setChangePasswordDialogOpen(true)}
                              spinnerColor={colors.blue1}
                              aria-label={'next'}
                            >
                              <Typography variant="button" className={classes.boldFont}>
                                Change Password
                              </Typography>
                            </LoadingButton>
                          </Box>
                          }
                        </Box>
                      }
                      {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) &&
                        <Box pb={2.5} pr={4} display="flex" style={{ width: '68vw', maxWidth: '6865rem' }}>
                          <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                            <Box flexGrow="1">
                              <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Department
                                </Typography>
                              </Box>

                              <Field as={Select} name="department_id" variant="outlined" className={classes.textField1}>
                                <MenuItem value={''}>Select</MenuItem>
                                {departmentList && departmentList.length > 0 && departmentList.map((item: any) => {
                                  return <MenuItem value={item.id}>{item.title}</MenuItem>
                                })}
                              </Field>
                            </Box>
                          </Box>
                          <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                            <Box flexGrow="1">
                              <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Unit
                                </Typography>
                              </Box>

                              <Field as={Select} name="unit_id" variant="outlined" className={classes.textField1}>
                                <MenuItem value={''}>Select</MenuItem>
                                {unitList && unitList.length > 0 && unitList.map((item: any) => {
                                  return <MenuItem value={item.id}>{item.name}</MenuItem>
                                })}
                              </Field>
                            </Box>
                          </Box>
                        </Box>
                      }
                      {loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) &&
                        <Box pb={2.5} pr={4} display="flex" style={{ width: '68vw', maxWidth: '6865rem' }}>
                          <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                            <Box flexGrow="1">
                              <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Department
                                </Typography>
                              </Box>

                              <Field as={Select} name="department_id" variant="outlined" className={classes.textField1} disabled={user?.department_id ? true : false}>
                                <MenuItem value={''}>Select</MenuItem>
                                {departmentList && departmentList.length > 0 && departmentList.map((item: any) => {
                                  return <MenuItem value={item.id}>{item.title}</MenuItem>
                                })}
                              </Field>
                            </Box>
                          </Box>
                          <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                            <Box flexGrow="1">
                              <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Unit
                                </Typography>
                              </Box>

                              <Field as={Select} name="unit_id" variant="outlined" className={classes.textField1} disabled={user?.unit_id ? true : false}>
                                <MenuItem value={''}>Select</MenuItem>
                                {unitList && unitList.length > 0 && unitList.map((item: any) => {
                                  return <MenuItem value={item.id}>{item.name}</MenuItem>
                                })}
                              </Field>
                            </Box>
                          </Box>
                        </Box>
                      }
                      <Box pb={2.5} pr={4} style={{ width: '100%' }} display="flex">
                        <Box flexGrow="1" style={{ paddingRight: '50px' }}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Notes
                            </Typography>
                          </Box>

                          <Field multiline rows={3} fullWidth
                            style={{ background: isError && inputs === 4 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 4)}
                            as={TextField}
                            inputProps={{ maxLength: 251 }}
                            disabled={isEdit ? false : true}
                            name="notes"
                            size="small"
                            type="text"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {''}
                            </Typography>
                            <Typography style={{ color: values.notes?.length > 250 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.notes?.length === 251 ? 250 : values.notes?.length}/250
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {isEdit &&
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', flexDirection: 'column', alignItems: 'flex-end' }}>
                          {loggedInUser && (loggedInUser.primary_site_admin === null || loggedInUser.primary_site_admin === false) && ![ROLES.REFERRAL].includes(loggedInUser.role) && <LoadingButton
                            className={classes.deleteButton}
                            color="secondary"
                            variant="contained"
                            onClick={() => deletePopup()}
                          >
                            Delete
                          </LoadingButton>}
                          {loggedInUser && loggedInUser.role === ROLES.SITE_ADMIN && loggedInUser.primary_site_admin && <Typography style={{ color: '#0060B2', marginTop: '20px', cursor: 'pointer' }} onClick={(e) => handleOpenSiteAdminPopup()}>Designate new Primary Site Admin</Typography>}
                        </Box>
                      }
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Box> :
          <Box pb={4} pt={3} px={4} display="flex">
            <Formik
              enableReinitialize={true}
              initialValues={viewData}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={profileSchema}
            >
              <Box>
                <Form className={classes.formCont}>
                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          First Name*
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled={true}
                        name="firstName"
                        size="small"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>

                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Last Name*
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled={true}
                        name="lastName"
                        size="small"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    {loggedInUser && loggedInUser.role === ROLES.PATIENT ? <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Patient ID*
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled
                        name="patient_id"
                        size="small"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box> :
                      <Box flexGrow="1">
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Role*
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          disabled
                          name="role"
                          size="small"
                          type="text"
                          variant="outlined"
                          style={{ width: '100%' }}
                        />
                      </Box>
                    }
                  </Box>

                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Phone Number
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled={true}
                        name="mobilePhone"
                        size="small"
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                        }}
                        type="number"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                  <Box pb={2.5} pr={4} className={classes.textFieldContainer}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Email Address*
                      </Typography>
                    </Box>

                    <Field
                      as={TextField}
                      disabled={true}
                      className={classes.textField}
                      name="emails"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Date of Birth
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled={true}
                        name="birthDate"
                        size="small"
                        format="MM/dd/yyyy"
                        type="date"
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputProps={{ inputProps: { max: disDate } }}
                      />
                    </Box>
                  </Box>

                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Address
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled={true}
                        name="homeAddress"
                        size="small"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          City
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        disabled={true}
                        name="citys"
                        size="small"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>

                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          State
                        </Typography>
                      </Box>

                      <Field disabled={true} as={Select} name="state" variant="outlined" className={classes.textField1}>
                        <MenuItem value={''}>Select</MenuItem>
                        {stateList && stateList.length > 0 && stateList.map((item: any) => {
                          return <MenuItem value={item.state}>{item.state}</MenuItem>
                        })}
                      </Field>
                    </Box>
                  </Box>
                  <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Zip Code
                        </Typography>
                      </Box>

                      <Field
                        as={TextField}
                        value={zip} onChange={(e: any) => handelChange(e.target.value)}
                        disabled={true}
                        name="zip"
                        size="small"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                  {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) &&
                    <Box pb={2.5} pr={4} display="flex" style={{ width: '68vw', maxWidth: '6865rem' }}>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Department
                            </Typography>
                          </Box>

                          <Field disabled={true} as={Select} name="department_id" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {departmentList && departmentList.length > 0 && departmentList.map((item: any) => {
                              return <MenuItem value={item.id}>{item.title}</MenuItem>
                            })}
                          </Field>
                        </Box>
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Unit
                            </Typography>
                          </Box>

                          <Field disabled={true} as={Select} name="unit_id" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {unitList && unitList.length > 0 && unitList.map((item: any) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>
                            })}
                          </Field>
                        </Box>
                      </Box>
                    </Box>
                  }
                  {loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) &&
                    <Box pb={2.5} pr={4} display="flex" style={{ width: '68vw', maxWidth: '6865rem' }}>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Department
                            </Typography>
                          </Box>

                          <Field disabled={true} as={Select} name="department_id" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {departmentList && departmentList.length > 0 && departmentList.map((item: any) => {
                              return <MenuItem value={item.id}>{item.title}</MenuItem>
                            })}
                          </Field>
                        </Box>
                      </Box>
                      <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                        <Box flexGrow="1">
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Unit
                            </Typography>
                          </Box>

                          <Field disabled={true} as={Select} name="unit_id" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {unitList && unitList.length > 0 && unitList.map((item: any) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>
                            })}
                          </Field>
                        </Box>
                      </Box>
                    </Box>
                  }
                  <Box pb={2.5} pr={4} display="flex" style={{ width: '68vw', maxWidth: '6865rem' }}>
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Notes
                        </Typography>
                      </Box>

                      <Field multiline rows={3}
                        as={TextField}
                        disabled={true}
                        name="notes"
                        size="small"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Formik>
          </Box>

        )}
      </Paper>
      {isOpen &&
        <Dialog handleClose={handleClose} open={isOpen}>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} mb={2} display="flex" justifyContent="space-between" style={{ background: '#F9FAFB', borderRadius: '10px' }}>
              <Typography color="textSecondary" variant="h3">Are you sure you want to delete your account? This cannot be undone.</Typography>
            </Box>
            <Box px={4} pb={2}>
              <Box flexGrow="1">
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Enter password to delete
                  </Typography>
                </Box>
                <TextField fullWidth name="input" autoComplete='off' variant='outlined' size='small' value={input} onChange={(e) => setInput(e.target.value)} />
              </Box>
            </Box>
            <Box
              className={classes.bottomBox}
              display="flex"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <LoadingButton
                className={classes.secondaryButton}
                color="secondary"
                onClick={handleClose}
                variant="contained"
                aria-label="cancel"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                className={classes.deleteButton}
                color="secondary"
                onClick={deleteProfile}
                variant="contained"
                aria-label="confirm"
              >
                Delete
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      {isSSOOpen &&
        <Dialog handleClose={handleClose} open={isSSOOpen}>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} mb={2} display="flex" justifyContent="space-between" style={{ background: '#F9FAFB', borderRadius: '10px' }}>
              <Typography color="textSecondary" variant="h3">Are you sure you want to delete your account? This cannot be undone.</Typography>
            </Box>
            <Box px={4} pb={2}>
              <Box flexGrow="1">
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Enter Verification Code Sent To Email
                  </Typography>
                </Box>
                <TextField fullWidth name="otp" variant='outlined' size='small' value={otp}
                  onChange={(e) => isNumber(e.target.value)} />
                <Box pt={0.75} display="flex" justifyContent="space-between">
                  <Typography color="error" variant="subtitle2">
                    {otpVerification?.isVerified === false ? otpVerification?.message : ''}
                  </Typography>
                  <Typography color="primary" variant="subtitle2" style={{ cursor: 'pointer' }} onClick={() => resendOTP('DELETE_ACCOUNT')}>
                    Resend Code
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.bottomBox}
              display="flex"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <LoadingButton
                className={classes.secondaryButton}
                color="secondary"
                onClick={handleClose}
                variant="contained"
                aria-label="cancel"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                className={classes.deleteButton}
                color="secondary"
                onClick={deleteProfile}
                variant="contained"
                aria-label="confirm"
              >
                Delete
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      {siteAdminPopup &&
        <Dialog handleClose={handleClose} open={siteAdminPopup}>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} mb={2} display="flex" justifyContent="space-between" style={{ background: '#F9FAFB', borderRadius: '10px' }}>
              <Typography color="textSecondary" variant="h3">Are you sure you want to designate another primary site admin?</Typography>
            </Box>
            <Box px={4} pb={2}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Select New Primary Site Admin*
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Select className={classes.textField2}
                  value={siteAdmin}
                  onChange={(e) => setSiteAdmin(e.target.value)}
                  variant='outlined'
                >
                  <MenuItem value={''}>
                    {secondarySiteAdmins && secondarySiteAdmins.length > 0 ? 'Select' : 'No record found'}
                  </MenuItem>
                  {secondarySiteAdmins && secondarySiteAdmins.map((user: any) => {
                    return (
                      <MenuItem value={user.user_role_id} style={{ padding: '0px' }}>
                        <ListItem style={{ padding: '2px 10px' }}>
                          <ListItemAvatar>
                            <Avatar><Person /></Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={user.first_name + ' ' + user.last_name} secondary={user.email} />
                        </ListItem>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {error && siteAdmin === '' ? (
                <Typography color="error" variant="subtitle2">
                  {'Please select primary site admin'}
                </Typography>
              ) : null}
            </Box>
            <Box px={4} pb={2}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Enter Password to confirm*
                </Typography>
              </Box>
              <TextField fullWidth name="input" variant='outlined' autoComplete='off' size='small' value={input} onChange={(e) => setInput(e.target.value)} />
              {error && input === '' ? (
                <Typography color="error" variant="subtitle2">
                  {'Please enter password'}
                </Typography>
              ) : null}
            </Box>
            <Box
              className={classes.bottomBox}
              display="flex"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <LoadingButton
                className={classes.secondaryButton}
                color="secondary"
                onClick={handleClose}
                variant="contained"
                aria-label="cancel"
              >
                Cancel
              </LoadingButton>

              <LoadingButton disabled={loading}
                className={classes.primaryButton}
                color="primary"
                onClick={newSiteAdmin}
                variant="contained"
                aria-label="confirm"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      {siteAdminSSOPopup &&
        <Dialog handleClose={handleClose} open={siteAdminSSOPopup}>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} mb={2} display="flex" justifyContent="space-between" style={{ background: '#F9FAFB', borderRadius: '10px' }}>
              <Typography color="textSecondary" variant="h3">Are you sure you want to designate another primary site admin?</Typography>
            </Box>
            <Box px={4} pb={2}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Select New Primary Site Admin*
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Select className={classes.textField2}
                  value={siteAdmin}
                  onChange={(e) => setSiteAdmin(e.target.value)}
                  variant='outlined'
                >
                  <MenuItem value={''}>
                    {secondarySiteAdmins && secondarySiteAdmins.length > 0 ? 'Select' : 'No record found'}
                  </MenuItem>
                  {secondarySiteAdmins && secondarySiteAdmins.map((user: any) => {
                    return (
                      <MenuItem value={user.user_role_id} style={{ padding: '0px' }}>
                        <ListItem style={{ padding: '2px 10px' }}>
                          <ListItemAvatar>
                            <Avatar><Person /></Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={user.first_name + ' ' + user.last_name} secondary={user.email} />
                        </ListItem>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {error && siteAdmin === '' ? (
                <Typography color="error" variant="subtitle2">
                  {'Please select primary site admin'}
                </Typography>
              ) : null}
            </Box>
            <Box px={4} pb={2}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Enter Verification Code Sent To Email*
                </Typography>
              </Box>
              <TextField fullWidth name="otp" variant='outlined' size='small' value={otp}
                onChange={(e) => isNumber(e.target.value)} />
              <Box pt={0.75} display="flex" justifyContent="space-between">
                <Typography color="error" variant="subtitle2">
                  {otpVerification?.isVerified === false ? otpVerification?.message : ''}
                </Typography>
                <Typography color="primary" variant="subtitle2" style={{ cursor: 'pointer' }} onClick={() => resendOTP('DESIGNATE_PSITEADMIN')}>
                  Resend Code
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.bottomBox}
              display="flex"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <LoadingButton
                className={classes.secondaryButton}
                color="secondary"
                onClick={handleClose}
                variant="contained"
                aria-label="cancel"
              >
                Cancel
              </LoadingButton>

              <LoadingButton disabled={loading}
                className={classes.primaryButton}
                color="primary"
                onClick={newSiteAdmin}
                variant="contained"
                aria-label="confirm"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: '0.0625rem solid #D1D6DC',
    borderRadius: '0.625rem 0.625rem 0 0',
    paddingLeft: '32px',
    paddingRight: '32px',
    marginBottom: '30px',
    marginLeft: '-32px',
    marginRight: '-32px',
    marginTop: '-25px',
    paddingTop: '22px',
    width: '-webkit-fill-available'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem',
    background: '#F9FAFB'
  },
  topBox1: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: '0.0625rem solid #D1D6DC',
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px',
    background: '#0060B2'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '2rem',
    color: 'red',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '2rem',
    color: '#0060B2',
    minWidth: '120px',
    marginRight: '20px'
  },
  textFieldContainer: {
    maxWidth: '18rem',
    width: '65vw'
  },
  textField: {
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  rememberLabel: {
    color: colors.text4
  },
  openDialogoeBtn: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    marginLeft: '1rem'
  },
  formCont: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  boldFont: {
    fontWeight: 600
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  },
  textField2: {
    width: '100%',
    height: '55px',
    padding: '0px'
  }
}));
