import React, { useState, useEffect } from 'react';
import { Box, Theme, Typography, makeStyles, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../../components/Dialog';
import { changeUserPasswordThunk } from '../../../../redux/thunk/thunks';
import { LoadingButton } from '../../../../components/LoadingButton';
import { RootState } from '../../../../redux/redux-store';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

interface ChangePasswordDialogueProps {
  open: boolean;
  id: string;
  handleClose: () => void;
}

interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePasswordDialogue = ({ open, id, handleClose }: ChangePasswordDialogueProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const changePasswordError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.changePasswordError
  );
  const changePasswordSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.changePasswordSuccess
  );

  useEffect(() => {
    if (changePasswordSuccess) {
      dispatch(setSuccessAction('changePasswordSuccess', false));
      handleClose();
    }
  }, [changePasswordSuccess]);

  const handleChangePassword = (values: FormValues) => {
    dispatch(changeUserPasswordThunk(id, values.currentPassword, values.newPassword));
  };

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .required('Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
        "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
      .required('Required')
  });

  const initialValues: FormValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };

  return (
    <Dialog handleClose={handleClose} open={open}>
      <Box className={classes.main} display="flex" flexDirection="column">
        <Box
          alignItems="center"
          className={classes.topBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2.5}
        >
          <Typography variant="h3">Change Password</Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          onSubmit={handleChangePassword}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={changePasswordSchema}
        >
          {({ errors, isSubmitting, setSubmitting, touched, values }) => {
            if (isSubmitting && changePasswordError) {
              setSubmitting(false);
            }

            return (
              <Form>
                <Box px={3} py={2.5}>
                  <Box pb={2.5}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Current Password
                      </Typography>
                    </Box>

                    <Field
                      as={TextField}
                      className={classes.textField}
                      name="currentPassword"
                      size="small"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors.currentPassword && touched.currentPassword ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.currentPassword}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2.5}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        New Password
                      </Typography>
                    </Box>

                    <Field
                      as={TextField}
                      className={classes.textField}
                      name="newPassword"
                      size="small"
                      variant="outlined"
                      type={showPassword2 ? 'text' : 'password'}
                      autoComplete='off'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              edge="end"
                            >
                              {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors.newPassword && touched.newPassword ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.newPassword}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Repeat New Password
                      </Typography>
                    </Box>

                    <Field
                      as={TextField}
                      className={classes.textField}
                      name="confirmNewPassword"
                      size="small"
                      variant="outlined"
                      type={showPassword1 ? 'text' : 'password'}
                      autoComplete='off'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              edge="end"
                            >
                              {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors.confirmNewPassword && touched.confirmNewPassword ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.confirmNewPassword}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
                <Box
                  className={classes.bottomBox}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <LoadingButton
                    className={classes.secondaryButton}
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    aria-label={'cancel'}
                  >
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    className={classes.primaryButton}
                    color="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    aria-label={'change password'}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '80vw',
    maxWidth: '30rem'
  },
  topBox: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    width: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    width: '120px',
    color: '#0060B2'
  },
  textField: {
    width: '100%'
  }
}));
